import React from 'react';
import {NotFoundCover} from '../views';
import Minimal from '../layouts/Minimal';
import WithLayout from '../layouts/WithChildrenLayout';
import {SEO} from "../components/seo";


const FourOFourPage = () => {
  // Translation temporarily disabled
  //const {t} = useTranslation();
  const t = (txt)=>txt;
  return (
    <WithLayout layout={Minimal}>
      <SEO title={t("Not Found!")}/>
      <NotFoundCover
        title={t("Not Found")}
        subtitle={t("Uh oh.")}
        action_intro={t("There’s nothing here, but if you feel this is an error please")}
        action_label={t("let us know")}
        back_label={t("Back")}
      />
    </WithLayout>
  )
};

export default FourOFourPage;